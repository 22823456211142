<template>
<div style="color:#047527">
   <span v-if="taxpercent>0">{{ taxpercent }}% - </span>
   
    <strong v-if="calculatedtax>0">{{ calculatedtax }}</strong>
    <!-- <strong v-else>0</strong> -->
   
</div>

</template>
<script>
import { mapGetters } from 'vuex'
export default {
    props:['productid','taxlabelid','cartitemindex'],
    computed:{
        ...mapGetters([
			'products','cartitems'
		]),
        tax(){
            let item = null
            if(this.cartitems!=null && this.cartitems.length>0){
                let cartitem = this.cartitems[this.cartitemindex]
                if(cartitem!=null && cartitem.collectedtaxes!=null){
                    let found = cartitem.collectedtaxes.find(block=>block.labelid==this.taxlabelid)
                    if(typeof found!=='undefined'){
                        item = found
                    }
                }
            }
            return item
        },
        taxpercent(){
            /*let found = this.products.find(block=>block.id==this.productid)
            let percent = 0;
            if(typeof found!=='undefined'){
                let tax = found.tax;
                if(tax!=null){
                    let individualtax = tax.find(block=>block.labelid==this.taxlabelid)
                    if(typeof individualtax!=='undefined')
                        percent = individualtax.value1
                }
            }
            */
           
           let percent = 0;
           if(this.tax!=null){
               percent = this.tax.percent
           }
            return percent
        },
        calculatedtax(){
            // console.log("tax s",this.tax)
            /*let item = this.cartitems[this.cartitemindex]
            if(item!=null){
                let tax = item.calculatedtax
                if(tax!=null){
                    let individualtax = tax.find(block=>block.labelid==this.taxlabelid)
                    if(typeof individualtax!=='undefined')
                        return individualtax.value1
                }
            }*/
            let percent = 0;
            if(this.tax!=null){
               percent = this.tax.value1
            }
            return percent;
        }
    }
}
</script>