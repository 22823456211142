<template>
<div>
    <div class="form-group row" :class="{ 'form-group--error': $v.id.$error }">
        <label class="col-lg-4 col-md-3  control-label">{{label}}</label>
        <div :class="col">
            <input class="form-control form-cascade-control input-small" @input="referralSearch()" v-model="$v.id.$model" type="text" required>
            <span class="error" v-if="!$v.id.required">Field is required</span>
            <span class="error" v-if="!$v.id.minLength">ID must have at least {{$v.id.$params.minLength.min}} letters.</span>
        </div>
        <div class="input-group-prepend">
            <span class="input-group-text"> <i class="fa fa-question-circle"></i></span>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-4"></div>
        <div class="col-md-2" style="margin-top:5px;" v-if="referraluser!=null">
            <div class="referal-text" ><strong>{{ referraluser.name }}</strong></div>
        </div>
        <div class="col-md-2" style="margin-top:5px;" v-if="referraluser!=null">
            <display-mobiles :mobiles="referraluser.mobiles" class="referal-text"></display-mobiles>
        </div> <!-- input-group.// -->					    
        <div class="col-md-4" style="margin-top:5px;" v-if="referraluser!=null">
            <display-first-address :addresses="referraluser.addressess" class="referal-text"></display-first-address>
        </div> <!-- input-group.// -->	
    </div>				    
</div>

</template>
<script>
import {mapGetters} from 'vuex'
import DisplayFirstAddress from '../address/DisplayFirstAddress.vue'
import DisplayMobiles from '../mobile/DisplayMobiles.vue'
import { required, minLength } from 'vuelidate/lib/validators'
import debounce from 'lodash.debounce'
export default {
    props:['label','col'],
    components: {
    	DisplayFirstAddress,DisplayMobiles
    },
    data(){
        return {
            id:'VSDIGI-'
        }
    },
    validations:{
        id:{
            required,
            minLength: minLength(6)
        }
    },
    mounted(){
        if(this.referraluser!=null){
            this.id = this.referraluser.generatedid
        }
    },
    watch:{
        referraluser:function(){
            if(this.referraluser!=null){
                this.id = this.referraluser.generatedid
            }
        }
    },
    computed:{
        ...mapGetters([
		    'referraluser'
		])
    },
    methods:{

        referralSearch: debounce (
            function() {
            let param = {generatedid:this.id}
            this.$http.post('api/user/getuserbygeneratedid',param)
            .then(response=>this.processUserResponse(response.data))
            .catch(error=>console.log(error));
        },1000),
        // referralSearch(e) {
        //     e.target.value=e.target.value.toUpperCase()
        //         clearTimeout(this.debounce)
        //         this.debounce = setTimeout(() => {
        //             this.referralsearchajax();
        //         }, 600)
        // },
        processUserResponse(data){
            if(Object.keys(data).length>0){
                this.$store.commit('assignreferraluser',data)
            }else {
                this.resetdata()
            }
        },
        resetdata(){
            this.id='VSDIGI-'
            this.$store.commit('assignreferraluser',null)
        }
    }
}
</script>