<template>
<div>
    <div class="form-group row" :class="{ 'form-group--error': $v.downpayment.$error }">
        <label class="col-lg-4 col-md-3 control-label">Downpayment<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
        <div class="col-lg-6 col-md-9">
            <input class="form-control form-cascade-control input-small"  v-model="$v.downpayment.$model" type="number">
            <span class="error" v-if="!$v.downpayment.required">Field is required</span>
            <div class="error" v-if="!$v.downpayment.isUnique">Downpayment Should not be 0</div>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-lg-4 col-md-3 control-label">Balance<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
        <div class="col-lg-6 col-md-9">
        <input disabled class="form-control form-cascade-control input-small" v-model="getbalance" type="number">
        </div>
    </div>
    
    <div class="form-group row" :class="{ 'form-group--error': $v.numberofemi.$error }">
        <label class="col-lg-4 col-md-3 control-label">No. Of EMI<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
        <div class="col-lg-6 col-md-9">
            <input class="form-control form-cascade-control input-small" v-model="$v.numberofemi.$model" type="number" required>
            <span class="error" v-if="!$v.numberofemi.required">Field is required</span>
            <span class="error" v-if="!$v.numberofemi.maxLength">Emi must have at least less than {{$v.numberofemi.$params.maxLength.max}} letters.</span>
            <span class="error" v-if="!$v.numberofemi.isUnique">No. of Emi Should not be 0</span>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-lg-4 col-md-3 control-label">Monthly EMI Charges<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
        <div class="col-lg-6 col-md-9">
            <div class="form-control form-cascade-control input-small"><span>{{ this.totalemicost??0 }} per month</span></div>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-lg-4 col-md-3 control-label">Total EMI Charges<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
        <div class="col-lg-6 col-md-9">
            <div class="form-control form-cascade-control input-small"><span v-if="numberofemi>0">{{ this.totalemicost }} * {{ this.numberofemi }} = {{ this.totalemicost * this.numberofemi??1  }}</span></div>
        </div>
    </div>
    <div class="form-group row">
        <label class="col-lg-4 col-md-3 control-label">Monthly Installment<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
        <div class="col-lg-6 col-md-9">
            <input disabled class="form-control form-cascade-control input-small" v-model="emiamt" type="number" required>
        </div>
        <!-- <div style="padding: 5px;color: #0a3d58;"><i class='bx bx-rupee'></i>{{ this.totalemicost }} per month</div> -->
    </div>
    <div class="form-group row">
        <label class="col-lg-4 col-md-3 control-label">Remaining Amount<span style="color: rgba(255, 0, 0, 0.67)">*</span></label>
        <div class="col-lg-6 col-md-9">
            <input disabled class="form-control form-cascade-control input-small" type="number" v-model="totalloanamount">
        </div>
    </div>
    <div class="form-group row">
        <label class="col-lg-4 col-md-3 control-label">Emi Type</label>
        <div class="col-lg-6 col-md-9">
        <select  v-model="recurringtype" class="form-control delradius form-cascade-control">
            <option v-for="option in getSelectedIdsFromMasterType([2],MASTER_RECURRING_TYPES)" v-bind:key="option.labelid" v-bind:value="option.labelid" v-text="option.label" />
        </select>
        </div>
    </div>

</div>
</template>
<script>
import { mapGetters } from 'vuex'
import ConstantsVue from '../../components/utilities/Constants.vue'
import { required, maxLength } from 'vuelidate/lib/validators'
export default {
    mixins:[ConstantsVue],
    props:["amount","manualid","startdate"],
    data(){
        return {
            recurringtype:2,
            numberofemi:0,
            emi:0,
            downpayment:0,
            products:[],
            totalemicost:0,
        }
    },
    validations:{
        numberofemi:{
            required,
            maxLength: maxLength(2),
            isUnique(value){
                if(value!=0){
                    return true
                }
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(typeof value === 'number' && value==0)
                    }, 350 + Math.random() * 300)
                })
            }
        },
        downpayment:{
            required,
            isUnique(value){
                if(value!=0){
                    return true
                }
                return new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(typeof value === 'number' && value==0)
                    }, 350 + Math.random() * 300)
                })
            }
        }
    },
    mounted(){
        if(this.loandetails != null){
            this.recurringtype = this.loandetails.recurringtype
            this.numberofemi = this.loandetails.numberofemi
          //  this.emiamt = this.loandetails.emi
           // this.getemi = this.loandetails.remainingamount
            this.downpayment = this.loandetails.downpayment
        }
        console.log("cart items here now")
        console.log(this.cartitems)
    },
    computed:{
        ...mapGetters([
            'loandetails','selectedproductid','allproducts','cartitems'
        ]),
        selectedproduct(){
            if(this.allproducts!=null){
               return  this.allproducts.find(block=>block.id==this.selectedproductid)
            }
             return [];
        },
        getbalance(){
            if(this.selectedproduct!=null || this.loandetails!=null)
                return parseFloat(parseFloat(this.amount) - parseFloat(this.downpayment)).toFixed(2)
            return 0
        },
        emiamt(){
            if(this.selectedproduct!=null || this.loandetails!=null){
                let balance = parseFloat((parseFloat(this.getbalance) / parseFloat(this.numberofemi))+this.totalemicost).toFixed(2)
                return balance ?? 0
            }
            return 0
        },
        totalloanamount(){
            if(this.selectedproduct!=null || this.loandetails!=null){
                let totalamount = parseFloat(this.emiamt) * parseFloat(this.numberofemi)
                return Math.round(totalamount) ?? 0;
            }
            return 0
        }
        
    },
    watch:{
        recurringtype:function(){
            this.updateloandetails()
        },
        numberofemi:function(){
            this.updateloandetails()
        },
        emiamt:function(){
            this.updateloandetails()
        },
        downpayment:function(){
            this.updateloandetails()
        },
        startdate:function(){
            this.updateloandetails()
        },
        // totalemi:function(){
        //     this.updateloandetails()
        // }
    },
    methods:{
        updateloandetails(){
            this.getemiamount()
            let manualid = this.manualid.toUpperCase().replace(/ +/g, "");
            let obj = {manualid: manualid, recurringtype:this.recurringtype,numberofemi:this.numberofemi,emi:this.emiamt,
                downpayment:this.downpayment,startdate:this.startdate,totalloanamount: this.totalloanamount}
            this.$store.commit('assignloandetails',obj)
            // console.log("loan detail", this.loandetails)
        },
        getemiamount(){
            if(this.cartitems.length>0){
                let temp = 0
                this.cartitems.forEach(element => {
                    if(element.product!=null){
                        if(element?.product?.emi_cost!=null){
                            let emi = JSON.parse(element?.product?.emi_cost)
                            temp = (temp + parseFloat(emi?.emi_cost)) * parseInt(element.quantity)
                        }
                    }
                });
                this.totalemicost = temp??1
            }
        }
    }
}
</script>